var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"master-group-belanja"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[(_vm.alert)?[_c('b-alert',{staticClass:"mt-2",attrs:{"variant":_vm.alertDetail.variant,"show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.alertDetail.title)+" ")]),(_vm.alertDetail.variant === 'danger')?_c('div',{staticClass:"alert-body"},[(typeof _vm.alertDetail.text === 'object')?_c('ul',_vm._l((_vm.alertDetail.text),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_vm._e()]):_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.alertDetail.text)+" ")])])]:_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"vi-fullname"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-fullname","required":"","name":"fullname","placeholder":"Full Name"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Username / NIK","label-for":"vi-username"}},[_c('validation-provider',{attrs:{"name":"Username / NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-username","disabled":"","name":"username","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"vi-phonenumber"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-phonenumber","name":"phonenumber","placeholder":"Phone Number"},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"vi-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-email","disabled":"","name":"useremail","placeholder":"Email"},model:{value:(_vm.useremail),callback:function ($$v) {_vm.useremail=$$v},expression:"useremail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-password","type":"password","name":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Retype-Password","label-for":"vi-retypepassword"}},[_c('validation-provider',{attrs:{"name":"Retype Password","rules":"required","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-retypepassword","type":"password","name":"retypepassword","placeholder":"Retype Password"},model:{value:(_vm.retypepassword),callback:function ($$v) {_vm.retypepassword=$$v},expression:"retypepassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")])],1)],1)],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }