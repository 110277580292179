<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <validation-observer ref="simpleRules">
            <template v-if="alert">
              <b-alert
                :variant="alertDetail.variant"
                class="mt-2"
                show
              >
                <h4 class="alert-heading">
                  {{ alertDetail.title }}
                </h4>
                <div
                  v-if="alertDetail.variant === 'danger'"
                  class="alert-body"
                >
                  <ul v-if="typeof alertDetail.text === 'object'">
                    <li
                      v-for="(item, index) in alertDetail.text"
                      :key="index"
                    >
                      {{ index }}:
                      <span
                        v-for="(child, indexs) in item"
                        :key="indexs"
                      >
                        {{ child }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  v-else
                  class="alert-body"
                >
                  {{ alertDetail.text }}
                </div>
              </b-alert>
            </template>

            <b-form @submit.prevent="validationForm()">
              <b-row>
                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Full Name"
                    label-for="vi-fullname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Full Name"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-fullname"
                        v-model="fullname"
                        required
                        name="fullname"
                        placeholder="Full Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Username / NIK"
                    label-for="vi-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username / NIK"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-username"
                        v-model="username"
                        disabled
                        name="username"
                        placeholder="Username"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Phone Number"
                    label-for="vi-phonenumber"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-phonenumber"
                        v-model="phonenumber"
                        name="phonenumber"
                        placeholder="Phone Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Email"
                    label-for="vi-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="vi-email"
                        v-model="useremail"
                        disabled
                        name="useremail"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Password"
                    label-for="vi-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required|confirmed:confirm"
                    >
                      <b-form-input
                        id="vi-password"
                        v-model="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                >
                  <b-form-group
                    label="Retype-Password"
                    label-for="vi-retypepassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Retype Password"
                      rules="required"
                      vid="confirm"
                    >
                      <b-form-input
                        id="vi-retypepassword"
                        v-model="retypepassword"
                        type="password"
                        name="retypepassword"
                        placeholder="Retype Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  class="mb-1"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                  >
                    Simpan
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BButton, BCol, BCard, BFormGroup, BAlert, BFormInput,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { required, email } from '@validations'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BAlert,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
      userData: getUserData(),
      profileData: {},
      alert: false,
      edit: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      id_data: '',
      fullname: '',
      username: '',
      useremail: '',
      phonenumber: '',
      password: '',
      retypepassword: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/user/update', {
        params: {
          token: localStorage.getItem('userToken'),
          userid: this.userData.userid,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.profileData = res.data.data
            this.editTemplate(this.profileData)
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    editTemplate(data) {
      this.id_data = data.userid
      this.fullname = data.fullname
      this.username = data.username
      this.useremail = data.email
      this.phonenumber = data.phonenumber
      this.edit = true
    },
    simpanTemplate() {
      const formData = {
        userid: this.id_data,
        fullname: this.fullname,
        username: this.username,
        phonenumber: this.phonenumber,
        email: this.useremail,
        group_id: this.profileData.group_id,
        instansi_id: this.profileData.group_id,
      }
      if (this.password !== '') {
        formData.password = this.password
        formData.password_confirm = this.retypepassword
      }

      this.$http.post('/user/update', formData, {
        params: {
          token: localStorage.getItem('userToken'),
          userid: this.id_data,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: res.data.message,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
